<template>
  <div>
    <h2>アカウント一覧</h2>
    <b-alert
      variant="primary"
      show
      class="mt-1"
    >
      <div class="alert-body">
        アカウントを作成したユーザの一覧を表示しています。
      </div>
    </b-alert>
    <b-tabs align="right">
      <b-tab>
        <template #title>
          <feather-icon icon="UsersIcon" />
          <span class="iconSmartPhone">お客様</span>
        </template>
        <div class="mt-3 text-center">
          <b-spinner
            v-if="status === 2"
            variant="primary"
          />
        </div>
        <Display
          v-if="status === 1"
          :data-list="userList"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="PocketIcon" />
          <span class="iconSmartPhone">コレクト</span>
        </template>
        <div class="mt-3 text-center">
          <b-spinner
            v-if="status === 2"
            variant="primary"
          />
        </div>
        <Display
          v-if="status === 1"
          :data-list="adminList"
        >
          管理者
        </Display>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BAlert, BTabs, BTab, BSpinner,
} from 'bootstrap-vue'
import Display from './Display.vue'
import useListUser from './useListUser'

export default {
  components: {
    BAlert,
    BTabs,
    BTab,
    BSpinner,
    Display,
  },
  directives: {
    Ripple,
  },
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const {
      userList,
      adminList,
      status,
    } = useListUser()

    return {
      userList,
      adminList,
      status,
    }
  },
}
</script>
