<template>
  <div class="comsumer-list">
    <b-row class="match-height">
      <b-col lg="12">
        <ListUser />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import ListUser from '@/components/admin/account/ListUser.vue'

export default {
  components: {
    BRow,
    BCol,
    ListUser,
  },
}
</script>

<style scoped>
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .comsumer-list{
    width: 60%;
    margin: 0 auto;
  }
}
</style>
