<template>
  <div>
    <b-list-group>
      <b-list-group-item
        active
      >
        <div class="d-flex justify-content-between">
          <div class="">
            <slot>ユーザ</slot>一覧
          </div>
          <div class="">
            全 {{ dataList.length }} 件
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
    <b-list-group>
      <b-list-group-item
        v-for="(item, index) in dataList"
        :key="index"
      >
        <b-row>
          <!-- <b-col lg="1" cols="2">
          </b-col> -->
          <b-col lg="6">
            <div class="d-flex">
              <b-avatar
                size="30"
                :variant="`light-${getColor(index)}`"
              >
                {{ String(item.mail).substring(0, 1).toUpperCase() }}
              </b-avatar>
              <div
                v-if="item.provider === 'twitter'"
                class="ml-1"
              >
                <b-badge
                  pill
                  variant="info"
                >
                  <feather-icon
                    icon="TwitterIcon"
                    size="30"
                    class="mr-50"
                  />
                  <span class="">{{ item.twitterName }}</span>
                </b-badge>
              </div>
              <div
                v-else
                class="ml-1"
              >
                {{ item.mail }}
              </div>
            </div>
          </b-col>
          <b-col>
            <div class="d-flex justify-content-between">
              <div class="">
                <small>作成日：{{ formatDateMinutes(item.insertTime) }}</small>
              </div>
              <div class="">
                <div v-if="item.isUserProfile">
                  <b-badge
                    pill
                    variant="success"
                  >
                    <feather-icon
                      icon="CheckIcon"
                      size="10"
                      class=""
                    />
                    <span class="labeltext">
                      <small>プロフ</small>
                    </span>
                  </b-badge>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import {
  BRow, BCol, BListGroup, BListGroupItem, BAvatar, BBadge,
} from 'bootstrap-vue'
import formatDateMinutes from '@/components/js/filter/formatDateMinutes'

export default {
  components: {
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BAvatar,
    BBadge,
  },
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const colors = ['primary', 'secondary', 'success', 'warning', 'danger', 'info']
    const getColor = index => {
      const m = index % colors.length
      return colors[m]
    }
    return {
      formatDateMinutes,
      getColor,
    }
  },
}
</script>

<style scoped>
.title_ {
  font-size: 1.3rem;
  font-weight: 500;
}
.title_:hover {
  color: rgb(65, 56, 155);
}
</style>
