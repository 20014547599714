import { ref, onMounted } from '@vue/composition-api'
import GetUserList from '@/firestore/user/GetUserList'

export default function useListUser() {
  const userList = ref([])
  const userDoneList = ref([])
  const adminList = ref([])
  const status = ref(1)

  const fetch = async () => {
    status.value = 2
    const userLists = new GetUserList()
    const res = await userLists.fetch()
    userList.value = res.data.filter(v => v.type === 'consumer' && v.status !== 'done')
    userDoneList.value = res.data.filter(v => v.type === 'consumer' && v.status === 'done')
    adminList.value = res.data.filter(v => v.type === 'collect')
    status.value = 1
    // window.console.log('🐶', userList.value, adminList.value)
  }
  onMounted(async () => {
    fetch()
  })
  return {
    fetch,
    userList,
    userDoneList,
    adminList,
    status,
  }
}
